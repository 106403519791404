<template>
  <div class="media-planning-campaign-integration-dialog">
    <portal to="base-modal">
      <base-modal @closed_requested="close_integration_dialog">
        <form @submit.prevent="save_integration" ref="form">
          <h3>
            {{ campaign.title }} -
            <span v-if="integration.id">Integration ändern</span>
            <span v-else>Neue Integration</span>
            <time-tracking type="setup" :record="campaign" :init_start="true && false" ref="time_tracking"></time-tracking>
          </h3>

          <div v-if="errors" class="callout alert">
            <h5>Error</h5>
            <p v-for="error in errors">{{ error }}</p>
            <button @click.stop="errors = null" class="close-button" aria-label="Dismiss alert" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <fieldset>
            <div class="media-planning-campaign--channel-label">
              <channel-icon :channel_title="campaign.channel_title"></channel-icon>
              {{ campaign.channel_title }}:
              {{ campaign.title }}
            </div>

            <div class="media-planning-campaign--form-content">
              <label>Platforms:</label>
              <template v-for="platform in selectable_platforms">
                <input type="radio"
                       name="campaign_platform"
                       :value="platform.platform_id"
                       :id="`campaign_platform_${platform.platform_id}`"
                       v-model="integration.platform_id"
                       :disabled="integration.id || is_blocked || is_loading">
                <label :for="`campaign_platform_${platform.platform_id}`">
                  {{ platform.platform_title }}
                </label>
              </template>
            </div>

            <label>
              Code:
              <input type="text" v-model="integration.code" :disabled="is_blocked || is_loading">
            </label>

            <div v-if="integration.platform_title === 'ActiveAgent'" class="media-planning-campaign--form-content">
              <label>Resource type:</label>
              <template v-for="resource_type in active_agent_resource_types">
                <input type="radio"
                       name="resource_type"
                       :value="resource_type.code"
                       :id="`resource_type_${resource_type.code}`"
                       v-model="integration.resource_type"
                       :disabled="is_blocked || is_loading">
                <label :for="`resource_type_${resource_type.code}`">
                  {{ resource_type.title }}
                </label>
              </template>
            </div>

            <div v-if="integration.platform_title === 'Xandr'" class="media-planning-campaign--form-content">
              <label>Resource type:</label>
              <template v-for="resource_type in xandr_resource_types">
                <input type="radio"
                       name="resource_type"
                       :value="resource_type.code"
                       :id="`resource_type_${resource_type.code}`"
                       v-model="integration.resource_type"
                       :disabled="is_blocked || is_loading">
                <label :for="`resource_type_${resource_type.code}`">
                  {{ resource_type.title }}
                </label>
              </template>
            </div>

            <div v-if="integration.platform_title === 'Hawk'" class="media-planning-campaign--form-content">
              <label>Resource type:</label>
              <template v-for="resource_type in hawk_resource_types">
                <input type="radio"
                       name="resource_type"
                       :value="resource_type.code"
                       :id="`resource_type_${resource_type.code}`"
                       v-model="integration.resource_type"
                       :disabled="is_blocked || is_loading">
                <label :for="`resource_type_${resource_type.code}`">
                  {{ resource_type.title }}
                </label>
              </template>
            </div>

            <div v-if="integration.platform_title === 'SpotifyAds'" class="media-planning-campaign--form-content">
              <label>Resource type:</label>
              <template v-for="resource_type in spotify_ads_resource_types">
                <input type="radio"
                       name="resource_type"
                       :value="resource_type.code"
                       :id="`resource_type_${resource_type.code}`"
                       v-model="integration.resource_type"
                       :disabled="is_blocked || is_loading">
                <label :for="`resource_type_${resource_type.code}`">
                  {{ resource_type.title }}
                </label>
              </template>
            </div>

            <div v-if="integration.platform_title === 'Taboola'" class="media-planning-campaign--form-content">
              <label>Viewable Impressions:</label>
              <input :id="`campaign_${campaign.id}_taboola`"
                     v-model="integration.use_viewable_impressions"
                     :disabled="is_blocked || is_loading"
                     type="checkbox">
              <label :for="`campaign_${campaign.id}_taboola`">Zur Berechnung verwenden</label>
            </div>

            <div v-if="integration.platform_title === 'GoogleAds'" class="media-planning-campaign--form-content">
              <label>GoogleAds Customer:</label>
              <select v-model="integration.google_ads_customer_id" :disabled="is_blocked || is_loading">
                <option v-for="google_ads_customer in google_ads_customers"
                        :value="google_ads_customer.id">
                  {{ google_ads_customer.title }} ({{ google_ads_customer.id }})
                </option>
              </select>
            </div>

            <div v-if="integration.platform_title === 'ActiveAgent'" class="media-planning-campaign--form-content">
              <label>ActiveAgent Account:</label>
              <select v-model="integration.active_agent_account_id"
                      :disabled="is_blocked || is_loading || integration.id">
                <option v-for="active_agent_account in active_agent_accounts"
                        :value="active_agent_account.id">
                  {{ active_agent_account.username }}
                </option>
              </select>
            </div>

            <div v-if="integration.platform_title === 'Outbrain'" class="media-planning-campaign--form-content">
              <label>Outbrain Marketer:</label>
              <select v-model="integration.outbrain_marketer_id" :disabled="is_blocked || is_loading || integration.id">
                <option v-for="outbrain_marketer in outbrain_marketers"
                        :value="outbrain_marketer.id">
                  {{ outbrain_marketer.title }} ({{ outbrain_marketer.id }})
                </option>
              </select>
            </div>

            <div v-if="integration.platform_title && campaign.channel_title"
                 class="media-planning-campaign--form-content">
              <template v-for="ac in usable_additional_cost">
                <input :id="`ac_${ac.id}`"
                       v-model="integration.additional_cost_ids"
                       :value="ac.id"
                       :disabled="is_blocked || is_loading"
                       type="checkbox">
                <label :for="`ac_${ac.id}`">{{ ac.title }}</label>
              </template>
            </div>
          </fieldset>

          <div>
            <button v-if="integration.id"
                    @click.stop.prevent="delete_campaign_integration"
                    :class="disabled_button_class"
                    :disabled="is_blocked || is_loading"
                    class="media-planning-campaign-integration-dialog--button--alert">
              <i class="fi-alert"></i>
              Löschen ...
            </button>
            <button @click.stop="close_integration_dialog"
                    :disabled="is_blocked || is_loading"
                    :class="disabled_button_abort_class"
                    class="media-planning-campaign--button--abort">
              <i class="fi-x"></i>
              Abbrechen
            </button>
            <button v-if="integration_configured"
                    :class="disabled_button_class"
                    :disabled="is_blocked || is_loading"
                    class="media-planning-campaign--button">
              <i class="fi-save"></i>
              <span v-if="integration.id">Integration aktualisieren</span>
              <span v-else>Integration hinzufügen</span>
            </button>
            <button v-if="invidi_link" @click.stop.prevent="visit_invidi_campaign"
                    :title="`Invidi campaign: ${integration.code}`"
                    :disabled="is_blocked || is_loading"
                    class="media-planning-campaign-integration-dialog--button">
              <i class="fi-link"></i>
              Invidi
            </button>
          </div>
        </form>
      </base-modal>
    </portal>
  </div>
</template>

<script>
import "./media-planning-campaign-integration-dialog.scss";
import BaseModal from "../base-modal/base-modal-app"
import ChannelIcon from "../channel-icon/channel-icon-app"
import axios from "axios";
import VueNumeric from 'vue-numeric'
import TimeTracking from "../time-tracking/time-tracking-app.vue";

export default {
  name: "media-planning-campaign-integration-dialog",
  props: {
    media_planning: {
      required: true,
    },
    campaign: {
      required: true,
    },
    integration: {
      required: false,
      default() {
        return {
          id: null,
          platform_id: null,
          platform_title: null,
          code: "",
          resource_type: "insertion_order_id",
          use_viewable_impressions: null,
          google_ads_customer_id: null,
          active_agent_account_id: null,
          outbrain_marketer_id: null,
          additional_cost_ids: [],
        }
      },
    },
    integrations: {
      required: true,
    },
    google_ads_customers: {
      required: true,
    },
    active_agent_accounts: {
      required: true,
    },
    outbrain_marketers: {
      required: true,
    },
    is_blocked: {
      required: true,
    },
  },
  components: {
    TimeTracking,
    BaseModal,
    ChannelIcon,
    VueNumeric,
  },
  data() {
    return {
      additional_cost: [],
      active_agent_resource_types: [
        {title: "Insertion Order ID", code: "insertion_order_id"},
        {title: "Line Item ID", code: "line_item_id"},
      ],
      xandr_resource_types: [
        {title: "Insertion Order ID", code: "insertion_order_id"},
        {title: "Line Item ID", code: "line_item_id"},
      ],
      hawk_resource_types: [
        {title: "Hawk Media Plan ID", code: "mediaplan_id"},
        {title: "Hawk Campaign ID", code: "campaign_id"},
      ],
      spotify_ads_resource_types: [
        {title: "SpotifyAds Campaign", code: "CAMPAIGN"},
        {title: "SpotifyAds Ad Set", code: "AD_SET"},
      ],
      is_loading: false,
      errors: null,
    }
  },
  methods: {
    save_integration() {
      if (!this.integration_configured) return
      if (this.is_blocked) return
      if (this.is_loading) return

      this.is_loading = true;
      this.errors = null

      if (this.integration.id) {
        axios({
          method: 'post',
          url: `/media_plannings/${this.media_planning.id}/update_campaign_integration`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data: {
            campaign_id: this.campaign.id,
            campaign_integration_id: this.integration.id,
            code: this.integration.code,
            resource_type: this.integration.resource_type,
            use_viewable_impressions: this.integration.use_viewable_impressions,
            google_ads_customer_id: this.integration.google_ads_customer_id,
            active_agent_account_id: this.integration.active_agent_account_id,
            outbrain_marketer_id: this.integration.outbrain_marketer_id,
            additional_cost_ids: this.integration.additional_cost_ids,
          },
        }).then((_response) => {
          this.is_loading = false;
          this.close_integration_dialog()
        }).catch(error => {
          this.is_loading = false;
          this.errors = error.response.data.errors.map(error => error.title)
          console.log("ERROR", error.response.data);
          alert("Error saving integration")
        });
      } else {
        axios({
          method: 'post',
          url: `/media_plannings/${this.media_planning.id}/add_campaign_integration`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data: {
            campaign_id: this.campaign.id,
            platform_id: this.integration.platform_id,
            code: this.integration.code,
            resource_type: this.integration.resource_type,
            use_viewable_impressions: this.integration.use_viewable_impressions,
            google_ads_customer_id: this.integration.google_ads_customer_id,
            active_agent_account_id: this.integration.active_agent_account_id,
            outbrain_marketer_id: this.integration.outbrain_marketer_id,
            additional_cost_ids: this.integration.additional_cost_ids,
          },
        }).then((_response) => {
          this.is_loading = false;
          this.close_integration_dialog()
        }).catch(error => {
          this.is_loading = false;
          this.errors = error.response.data.errors.map(error => error.title)
          console.log("ERROR", error.response.data);
          alert("Error saving integration")
        });
      }
    },
    delete_campaign_integration() {
      if (this.is_blocked) return
      if (this.is_loading) return

      let confirmation = confirm("Willst du die Integration wirklich löschen?");
      if (!confirmation) return

      this.is_loading = true;
      this.errors     = null

      axios({
        method: 'delete',
        url: `/media_plannings/${this.media_planning.id}/delete_campaign_integration`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        data: {
          campaign_id: this.campaign.id,
          campaign_integration_id: this.integration.id,
        },
      }).then((_response) => {
        this.is_loading = false;
        this.$emit("closed_requested")
      }).catch(error => {
        this.is_loading = false;
        this.errors = error.response.data.errors.map(error => error.title)
        console.log("ERROR", error.response.data);
        alert("Error deleting integration")
      });
    },
    close_integration_dialog() {
      this.$refs.time_tracking.stop()
      this.$emit("closed_requested")
    },
    visit_invidi_campaign() {
      window.location.href = `/media_plannings/${this.media_planning.id}/invidi/${this.integration.code}`
    },
  },
  computed: {
    integration_configured() {
      if (!this.integration.platform_id) return false
      if (!this.integration.code) return false
      if (this.integration.platform_title === "GoogleAds") {
        if (!this.integration.google_ads_customer_id) return false
      }
      if (this.integration.platform_title === "ActiveAgent") {
        if (!this.integration.active_agent_account_id) return false
        if (!this.integration.resource_type) return false
      }
      if (this.integration.platform_title === "Xandr") {
        if (!this.integration.resource_type) return false
      }
      if (this.integration.platform_title === "Hawk") {
        if (!this.integration.resource_type) return false
      }
      if (this.integration.platform_title === "SpotifyAds") {
        if (!this.integration.resource_type) return false
      }
      if (this.integration.platform_title === "Outbrain") {
        if (!this.integration.outbrain_marketer_id) return false
      }
      return true
    },
    disabled_button_class() {
      if (!this.integration_configured) {
        return "media-planning-campaign-integration-dialog--button--disabled"
      }
      if (this.is_loading || this.is_blocked) {
        return "media-planning-campaign-integration-dialog--button--disabled"
      }
    },
    disabled_button_abort_class() {
      if (this.is_loading || this.is_blocked) {
        return "media-planning-campaign-integration-dialog--button--disabled"
      }
    },
    selectable_platforms() {
      const campaign_integration = this.integrations.find(integration => {
        return this.campaign.channel_title === integration.channel_title
      })

      return campaign_integration.platforms.filter(platform => platform.enabled)
    },
    invidi_link() {
      return this.integration.platform_title === "Invidi"
    },
    usable_additional_cost() {
      return this.additional_cost.reduce((aggregate, cost) => {
        if (!cost.channels.includes(this.campaign.channel_title)) return aggregate
        if (!cost.platforms.includes(this.integration.platform_title)) return aggregate

        aggregate.push(cost)
        return aggregate
      }, [])
    },
  },
  watch: {
    "integration.platform_id"() {
      const campaign_integration = this.integrations.find(integration => {
        return this.campaign.channel_title === integration.channel_title
      })

      const platform = campaign_integration.platforms.find(p => {
        return p.platform_id === this.integration.platform_id
      })

      this.integration.platform_title = platform.platform_title

      if (this.integration.platform_title !== "Taboola") {
        this.integration.use_viewable_impressions = null
      }

      if (this.integration.platform_title !== "GoogleAds") {
        this.integration.google_ads_customer_id = null
      }

      if (this.integration.platform_title !== "ActiveAgent") {
        this.integration.active_agent_account_id = null
      }

      if (this.integration.platform_title !== "Outbrain") {
        this.integration.outbrain_marketer_id = null
      }
    },
    "integration.code"() {
      if (this.integration.code !== this.integration.code.replaceAll(" ", "")) {
        this.integration.code = this.integration.code.replaceAll(" ", "")
      }
    },
  },
  mounted() {
    axios({
      method: 'get',
      url: `/additional_cost.json`,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    }).then(response => {
      this.additional_cost = response.data
    }).catch(error => {
      console.log("ERROR", error.response.data);
      alert("Error loading additional cost data")
    });
  },
}
</script>
