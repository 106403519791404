<template>
  <div class="time-tracking-dialog">
    <portal to="base-modal">
      <base-modal @closed_requested="close_time_tracking_dialog">
        <h3 v-if="time_tracking.id">Update time tracking</h3>
        <h3 v-else>Track time</h3>

        <form @submit.prevent="save_time_tracking" ref="form">
          <div v-if="errors" class="callout alert">
            <h5>Error</h5>
            <p v-for="error in errors">{{ error }}</p>
            <button @click.stop="errors = null" class="close-button" aria-label="Dismiss alert" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <fieldset>
            <label>
              Type:
              <select @change="select_type($event)" :disabled="time_tracking.id || type">
                <option value="setup" :selected="time_tracking.type === 'setup'">Setup</option>
                <option value="management" :selected="time_tracking.type === 'management'">Management</option>
                <option value="reporting" :selected="time_tracking.type === 'reporting'">Reporting</option>
                <option value="creative" :selected="time_tracking.type === 'creative'">Creative</option>
                <option value="offer" :selected="time_tracking.type === 'offer'">Offer</option>
                <option value="communication" :selected="time_tracking.type === 'communication'">Communication</option>
              </select>
            </label>

            <vue-ctk-date-time-picker id="started_at"
                                      format="YYYY-MM-DD hh:mm a"
                                      hint="Start datetime"
                                      color="#36bbb1"
                                      button-color="#36bbb1"
                                      label="Started at"
                                      formatted="llll"
                                      locale="de"
                                      output-format="YYYY-MM-DDTHH:mm:ss.SSSZ"
                                      v-model="time_tracking.started_at"/>

            <vue-ctk-date-time-picker id="stopped_at"
                                      format="YYYY-MM-DD hh:mm a"
                                      hint="Stop datetime"
                                      color="#36bbb1"
                                      button-color="#36bbb1"
                                      label="Stopped at"
                                      formatted="llll"
                                      locale="de"
                                      output-format="YYYY-MM-DDTHH:mm:ss.SSSZ"
                                      :min-date-time="time_tracking.started_at"
                                      v-model="time_tracking.stopped_at"/>
          </fieldset>

          <fieldset class="time-tracking-dialog--intervals">
            <button class="time-tracking--button" @click.stop.prevent="set_interval(5 * 60 * 1000)">
              5 m
            </button>
            <button class="time-tracking--button" @click.stop.prevent="set_interval(10 * 60 * 1000)">
              10 m
            </button>
            <button class="time-tracking--button" @click.stop.prevent="set_interval(15 * 60 * 1000)">
              15 m
            </button>
            <button class="time-tracking--button" @click.stop.prevent="set_interval(20 * 60 * 1000)">
              20 m
            </button>
            <button class="time-tracking--button" @click.stop.prevent="set_interval(30 * 60 * 1000)">
              30 m
            </button>
            <button class="time-tracking--button" @click.stop.prevent="set_interval(45 * 60 * 1000)">
              45 m
            </button>
            <button class="time-tracking--button" @click.stop.prevent="set_interval(60 * 60 * 1000)">
              1 h
            </button>
            <button class="time-tracking--button" @click.stop.prevent="set_interval(1.5 * 60 * 60 * 1000)">
              1.5 h
            </button>
            <button class="time-tracking--button" @click.stop.prevent="set_interval(2 * 60 * 60 * 1000)">
              2 h
            </button>
            <button class="time-tracking--button" @click.stop.prevent="set_interval(3 * 60 * 60 * 1000)">
              3 h
            </button>
          </fieldset>

          <div>
            <a v-if="time_tracking.id"
               @click.stop.prevent="delete_time_tracking"
               :class="disabled_button_class"
               class="time-tracking-dialog--button--alert">
              <i class="fi-alert"></i>
              Delete ...
            </a>
            <a @click="close_time_tracking_dialog" class="time-tracking-dialog--button--abort">
              <i class="fi-x"></i>
              Abort
            </a>
            <button v-if="time_tracking_configured" :class="disabled_button_class">
              <i class="fi-save"></i>
              <span>Save time tracking</span>
            </button>
          </div>
        </form>
      </base-modal>
    </portal>
  </div>
</template>

<script>
import "./time-tracking-dialog.scss"
import BaseModal from "../base-modal/base-modal-app"
import axios from "axios"

export default {
  name: "time-tracking-dialog",
  props: {
    type: {
      required: false,
    },
    time_tracking: {
      required: false,
      default: function () {
        return {
          id: null,
          type: this.type || "setup",
          started_at: null,
          stopped_at: null,
        }
      }
    },
    campaign_id: {
      required: false,
    },
    offer_id: {
      required: false,
    },
    channel_id: {
      required: false,
    },
    advertiser_id: {
      required: false,
    },
  },
  components: {
    BaseModal
  },
  data() {
    return {
      loading: false,
      errors: null,
    }
  },
  methods: {
    select_type(event) {
      this.time_tracking.type = event.target.value
      this.type = event.target.value
    },
    save_time_tracking() {
      if (!this.time_tracking_configured) return
      if (this.loading) return

      this.loading = true
      this.errors = null

      if (this.time_tracking.id) {
        axios({
          method: 'put',
          url: `/time_trackings/${this.time_tracking.id}/update`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data: {
            id: this.time_tracking.id,
            started_at: this.time_tracking.started_at,
            stopped_at: this.time_tracking.stopped_at,
          },
        }).then((_response) => {
          this.loading = false
          this.close_time_tracking_dialog()
        }).catch(error => {
          this.loading = false
          this.errors = error.response.data.errors.map(error => error.title)
          console.log("ERROR", error.response.data)
          alert("Error saving time tracking")
        })
      } else {
        axios({
          method: 'post',
          url: `/time_trackings`,
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          data: {
            id: this.time_tracking.id,
            campaign_id: this.campaign_id,
            type: this.time_tracking.type,
            offer_id: this.offer_id,
            channel_id: this.channel_id,
            advertiser_id: this.advertiser_id,
            started_at: this.time_tracking.started_at,
            stopped_at: this.time_tracking.stopped_at,
          },
        }).then((_response) => {
          this.loading = false
          this.close_time_tracking_dialog()
        }).catch(error => {
          this.loading = false
          this.errors = error.response.data.errors.map(error => error.title)
          console.log("ERROR", error.response.data)
          alert("Error saving time tracking")
        })
      }
    },
    delete_time_tracking() {
      let confirmation = confirm("Really delete the time tracking?")
      if (!confirmation) return
      axios({
        method: 'delete',
        url: `/time_trackings/${this.time_tracking.id}/delete`,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      }).then((_response) => {
        this.loading = false
        this.close_time_tracking_dialog()
      }).catch(error => {
        this.loading = false
        this.errors = error.response.data.errors.map(error => error.title)
        console.log("ERROR", error.response.data)
        alert("Error deleting time tracking")
      })
    },
    set_interval(milliseconds) {
      let now = new Date()
      this.time_tracking.started_at = new Date(now.getTime() - milliseconds).toISOString()
      this.time_tracking.stopped_at = now.toISOString()
    },
    close_time_tracking_dialog() {
      this.$emit("closed_requested")
    },
  },
  computed: {
    time_tracking_configured() {
      if (!this.time_tracking.started_at) return false
      if (!this.time_tracking.stopped_at) return false
      if (new Date(this.time_tracking.started_at) > new Date(this.time_tracking.stopped_at)) return false
      if (!this.time_tracking.type) return false
      return true
    },
    disabled_button_class() {
      if (this.time_tracking_configured) {
        return "time-tracking-dialog--button"
      } else {
        return "time-tracking-dialog--disabled"
      }
    },
  },
}
</script>
