<template>
  <div class="advertisers">
    <h1>
      Advertiser
    </h1>

    <div class="advertisers--commands">
      <a @click="open_advertiser_dialog()" class="advertisers--button">
        <i class="fi-plus"></i>
        Neuer Advertiser
      </a>
    </div>

    <table>
      <thead>
      <tr class="advertisers--th">
        <th>ID</th>
        <th>Titel</th>
        <th><abbr title="Individuelle Report Freischaltung">IRF</abbr></th>
        <th>Media plans</th>
        <th>Final reports</th>
        <th v-if="can_manage">Amazon Auth</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="advertiser in displayable_advertisers"
          @click="open_advertiser_dialog(advertiser)"
          class="advertisers--row">
        <td>{{ advertiser.id }}</td>
        <td>{{ advertiser.title }}</td>
        <td><boolean-icon :bool="advertiser.individual_approval" title="Individuelle Report Freischaltung"></boolean-icon></td>
        <td>
          <button v-if="advertiser.media_plannings_count"
                  @click.prevent.stop="visit_media_plannings(advertiser)">
            {{ advertiser.media_plannings_count }} Media plans
          </button>
        </td>
        <td>
          <button v-if="advertiser.final_reports_count"
                  @click.prevent.stop="visit_final_reports(advertiser)">
            {{ advertiser.final_reports_count }} Final reports
          </button>
        </td>
        <td v-if="can_manage">
          <pre v-if="advertiser.amazon_authorization_code">{{ advertiser.amazon_authorization_code}}</pre>
          <button @click.prevent.stop="visit_amazon_authorization_codes(advertiser)">
            <i class="fi-social-amazon"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>

    <advertiser-dialog v-if="current_advertiser"
                       :advertiser="current_advertiser"
                       @closed_requested="current_advertiser = null">
    </advertiser-dialog>

    <portal-target name="base-modal"></portal-target>
  </div>
</template>

<script>
import "./advertisers.scss";
import AdvertiserDialog from "../advertiser-dialog/advertiser-dialog-app"
import BooleanIcon from "../boolean-icon/boolean-icon";

export default {
  name: "advertisers",
  props: {
    original_advertisers: {
      required: true,
    },
    can_manage: {
      required: true,
    },
  },
  components: {
    AdvertiserDialog,
    BooleanIcon,
  },
  data() {
    return {
      advertisers: this.original_advertisers,
      current_advertiser: null,
    }
  },
  methods: {
    open_advertiser_dialog(advertiser = null) {
      if (advertiser) {
        this.current_advertiser = Object.assign({}, advertiser)
      } else {
        this.current_advertiser = {
          id: null,
          title: null,
        }
      }
    },
    visit_media_plannings(advertiser) {
      window.location.href = `media_plannings?filter_live=1&filter_reporting=1&advertiser_id=${advertiser.id}`
    },
    visit_final_reports(advertiser) {
      window.location.href = `/final_reports?advertiser_id=${advertiser.id}`
    },
    visit_amazon_authorization_codes(advertiser) {
      window.location.href = advertiser.amazon_consent_request_url
    },
  },
  computed: {
    displayable_advertisers() {
      return this.advertisers.filter(advertiser => {
        if (advertiser.deleted) return
        if (!advertiser.persisted) return
        return true
      }).sort((c1, c2) => {
        const name1 = c1.title.toLowerCase()
        const name2 = c2.title.toLowerCase()
        if (name1 < name2) {
          return -1
        } else {
          return 1
        }
      })
    }
  },
  channels: {
    AdvertiserRegisteredChannel: {
      received(advertiser_json) {
        console.log("AdvertiserRegisteredChannel received")
        const advertiser = JSON.parse(advertiser_json);
        this.advertisers.push(advertiser)
        this.$cable.subscribe({
                                channel: 'AdvertiserUpdatedChannel',
                                id: advertiser.id
                              });
        this.$cable.subscribe({
                                channel: 'AdvertiserDeletedChannel',
                                id: advertiser.id
                              });
      },
    },
    AdvertiserUpdatedChannel: {
      received(advertiser_json) {
        console.log("AdvertiserUpdatedChannel received")
        const received_advertiser = JSON.parse(advertiser_json);
        const advertiser          = this.advertisers.find(advertiser => {
          return advertiser.id === received_advertiser.id
        })
        if (!advertiser) return
        Object.assign(advertiser, received_advertiser);
        console.log("Advertiser updated", advertiser)
      },
    },
    AdvertiserDeletedChannel: {
      received(advertiser_json) {
        console.log("AdvertiserDeletedChannel received")
        const received_advertiser = JSON.parse(advertiser_json);
        const index               = this.advertisers.findIndex(advertiser => {
          return advertiser.id === received_advertiser.id
        })
        if (index === -1) return
        this.advertisers.splice(index, 1);
      },
    },
  },
  mounted() {
    this.$cable.subscribe({
                            channel: 'AdvertiserRegisteredChannel',
                            stream: "advertiser_registered",
                          })
    this.advertisers.forEach((advertiser => {
      this.$cable.subscribe({
                              channel: 'AdvertiserUpdatedChannel',
                              id: advertiser.id
                            });
      this.$cable.subscribe({
                              channel: 'AdvertiserDeletedChannel',
                              id: advertiser.id
                            });
    }))
  },
}
</script>
